import React from "react";
import Footer from "./../Footer";


const HowItWorks = () => {
  return (
    <div>
       HowItWorks will be here
      <div className="fp-auto-height footer-wrapper">
        <Footer />
      </div>
    </div>
  );
};

export default HowItWorks;
