import React from "react";
import "./App.css";
import "./assets/sass/main.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Layout from "./Layout";
import useUserSnap from "./userSnap";
import useTracking from "./components/hooks/useTracking";
import useTrackExternalLink from "./components/hooks/useTrackExternalLink";
import OurBelief from './components/OurBelief/OurBelief';
import HowItWorks from './components/HowItWorks/HowItWorks';
import Missions from './components/Missions/Missions';
import ModalStory from './components/ModalStory';


function App() {
  useUserSnap();
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
}

const AppRoutes = () => {
  useTracking();
  useTrackExternalLink();

  return (
    <Routes>
      <Route default element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route path="/disc/:id" element={<ModalStory/>}/>
        <Route path="/ourbelief" element={<OurBelief />} />
        <Route path="/howitworks" element={<HowItWorks />} />
        <Route path="/missions" element={<Missions />} />
      </Route>
    </Routes>
  );
};

export default App;
