import React from "react";

const ScrollToSection = ({ className, scrollToSection}) => {
   
  return (
    <div onClick={scrollToSection} className={"arrow-navigatior " + className}>
      <span  className="triangle bounce"></span>
    </div>
  );
};

export default ScrollToSection;
