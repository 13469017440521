import React from "react";
import Header from "../Header";

const BannerMissions = () => {
  return (
    <div className="container banner-wrap">
      <Header />
      <div className="banner">
        <div className="banner-header-text">
          <h1 className="header-text">
          Text
          </h1>

          <p class="description">
          Text
           </p>
          <a href="/whatwecreate" rel="noreferrer">
                <button  className="btn btn--outline btn--outline--blackish">
                  Get Started
                </button>
            </a>
        </div>

        <div className="banner-header-image">
        
         
        </div>
      </div>
    </div>
  );
};

export default BannerMissions;
