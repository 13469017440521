import React from "react";
import Lion from "../assets/img/home/the-mission/lion.svg";
import MapLine from "../assets/img/home/the-mission/map-line.svg";

const FirstMissionRevealed = () => {
    const nextSection = (e) => {
        e.preventDefault();
        document.getElementById("whyWeCare").scrollIntoView({behavior: "smooth"})
      }

    return (
        <div className="container items-column first-mission-revealed-section">
            <div id="mission" className="first-mission-revealed">
                <div className="first-mission-revealed-content">
                    {/* <h1 className="title-right">The 1st mission<br />
                        will be revealed on
                        <br />
                        <span className="white">
                          Sept 6th, 2022 </span></h1> */}
                    <div className="first-mission-revealed-content-description mtn-60 l-height">
                        <h1 className="header-text">
                            Join Our Mission
                        </h1>
                        <p className="description">
                            <ul>
                                <li>
                                We drop a new chapter of The Missionaries on Twitter and Discord each week.
                                </li>
                                <li>
                                Get whitelisted for the first Missionaries NFT drop - giving you rewards and VIP event access.
                                </li>
                                <li>Help The Missionaries complete increasingly difficult Missions that help spread our thanks. </li>
                                <li>Get crypto and an on-chain thank you note for each contribution you make to our Missions! </li>
                            </ul>
                        </p>

                        <div className="btn-wrapper">
                            <a onClick={nextSection} rel="noreferrer">
                                <button className="btn btn--greenish">
                                    Why we care
                                </button>
                            </a>
                        </div>
                    </div>

                    <img className="map-line-image" src={MapLine} alt="map Image" />
                </div>

                <div className="first-mission-revealed-image">
                    <img className="lion-image" src={Lion} alt="Lion Image" />
                </div>
            </div>
        </div>
    );
};

export default FirstMissionRevealed;
