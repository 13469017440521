import React, { useState, useRef, useMemo, useEffect } from "react";
import Room from "../assets/img/home/the-story/room.svg";
import RoomDark from "../assets/img/home/the-story/room1.svg";
import Bee from "../assets/img/home/the-story/bee.svg";

const TheStory = () => {
  const ref = useRef();

  const  useIsInViewport = (ref) => {
    const [isIntersecting, setIsIntersecting] = useState(false);
  
    const observer = useMemo(
      () =>
        new IntersectionObserver(([entry]) =>
        setTimeout(() => { 
          setIsIntersecting(entry.isIntersecting)
        }, 800)  
        ),
      [],
    );
    useEffect(() => {
      observer.observe(ref.current);
  
      return () => {
        observer.disconnect();
      };
    }, [ref, observer]);
  
    return isIntersecting;
  }
 
  const isInViewport = useIsInViewport(ref);
  return (
    <div className="container items-column the-story-section"  >
      <div id="whyWeCare" className="the-story">
        <div className="the-story-text">
          <h1 className="header-text">
          Why we care
          </h1>
          <p className="description">
            We are degens, engineers, designers,students, storytellers, DJs, investors, artists, and weirdos.
            <br/><br/>
            Some of us have done big things, others little things, and others not much of anything. 
            <br/><br/>
            We are joined by a common bond: we believe our epic Missions will change the world. 
            <br/><br/>
            As we thank more and more good contributions with crypto and an on-chain thank you note...
            <br/><br/>
            ... it’ll create a cascading effect. Together we’ll be better and richer in so many ways! 
          </p>
          <div className="the-story-img-bee">
            <img src={Bee} alt="bee" />
          </div>
        </div>
        <div className="the-story-img" ref={ref}>
          <img src={isInViewport ? Room : RoomDark} alt="img" className={isInViewport ? "room-img" : "room-dark-img"} />
        </div>
      </div>
    </div>
  );
};

export default TheStory;
