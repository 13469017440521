import React, { useState } from "react";
import BannerHeader from "./BannerHeader";
import StartLegend from "./StartLegend";
import Join from "./Join";
import Footer from "./Footer";
import TheStory from "./TheStory";
import TheMissionItems from "./TheMissionItems";
import TheFinalDestination from "./TheFinalDestination";
import FirstMissionRevealed from './FirstMissionRevealed';
import styled from "styled-components";
import ScrollToSection from './ScrollToSection';


const Home = () => {
 
  const scrollToBottom = () => {
    const banner = document.querySelector(".the-mission-items-wrapper");
    banner.scrollIntoView({ behavior: "smooth" });
  };

 
  return (
    <Wrapper >
      <div className="banner-container">
      <div className="bg-green banner-wrapper">
        <BannerHeader />
        <div className="next-section center bg-navy2">
        
        </div>
      </div>
      <ScrollToSection className="arrow-navigatior-top" scrollToSection={scrollToBottom}/>
      </div>
      <div className="the-mission-items-wrapper">
        <TheMissionItems />
        <div className="next-section"></div>
      </div>

      <div className="first-mission-revealed-wrapper">
        <FirstMissionRevealed />
        <div className="next-section"></div>
      </div>

      <div className="the-story-wrapper">
        <TheStory />
      </div>

      <div className="the-final-destination-wrapper">
        <TheFinalDestination />
    
      </div>
     
      <div className="join-wrapper">
        <Join />

      </div>
   

      <div className="start_legend-wrapper">
        <StartLegend />
      </div>
      <div className="footer-wrapper">
        <Footer />
      </div>
   
    </Wrapper>
  );
};

const Wrapper = styled.div`
  overflow-x: hidden;
`;

export default Home;
