import React from "react";

const Section3Goal = () => {
  return (
    <div className="container items-column section3goal-section">
      <div className="section3goal">
        <div className="section3goal-image">Img will be placed here</div>

        <div className="section3goal-content">
          <div className="section3goal-content-description mtn-60 l-height">
            <h1 className="header-text">
              We have set ourselves an almost impossible goal, have 1 Billion
              People contribute to a DAO in 2030... <br />
              No, We didnt hang too long around the bong...
            </h1>
            <p className="description">
              It even gets better, When you contribute to DAOs within these
              honorable missions you will earn an amount of $THRIVE,
              <br />
              <br />
              Huh what?
            </p>

            <a href="#" rel="noreferrer">
              <button className="btn">More about $Thrive</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section3Goal;
