import React from "react";
import RoadImg from "../assets/img/home/join/road.svg";
import RoadMobImg from "../assets/img/home/join/road-mob.svg";

const StartLegend = () => {
  return (
    <div className="container items-column start_legend-section">
      <div className="start_legend">
        <img src={RoadImg} className="road-img-truck" alt="RoadImg" />
        <img src={RoadMobImg} className="road-mob-img-truck" alt="RoadImg" />
      </div>  
    </div>  
  );
};

export default StartLegend;
