import React from "react";
import Globe from "../../assets/img/belief/globe.svg";

const Section2WhoWeAre = () => {
  return (
    <div className="container items-column section2-who-we-are-section">
      <div className="main-title items-center">
        <h6 className="mini-title primary">WHO WE ARE</h6>
      </div>

      <div className="section2-who-we-are">
        <div className="section2-who-we-are-content">
          <div className="section2-who-we-are-content-description mtn-60 l-height">
            <h1 className="header-text">
              By rewarding contributors of DAOs we can create a more equitable
              world. Sounds like real paradise right?
            </h1>
            <p className="description">
              we believe we can help our communities thrive by innovating in
              three areas:
              <span className="white bold">
                technology, relationships and insights.
              </span>
              <br />
              <br />
              <span className="white">
                There’s also a boring whitepaper if thats your thing. Otherwise
                keep scrolling.
              </span>
            </p>

            <a href="#" rel="noreferrer">
              <button className="btn btn--outline btn--outline--white">
                Download Whitepaper
              </button>
            </a>
          </div>
        </div>

        <div className="section2-who-we-are-image">
          <img className="globe-image" src={Globe} alt="Globe Image" />
        </div>
      </div>
    </div>
  );
};

export default Section2WhoWeAre;
