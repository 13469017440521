import React from "react";
import Footer from "./../Footer";
import BannerMissions from './BannerMissions';


const Missions = () => {
  return (
    <div>
      <div className="bg-green banner-wrapper">
        <BannerMissions />
        <div className="next-section center bg-navy2"></div>
      </div>
      <div className="fp-auto-height footer-wrapper">
        <Footer />
      </div>
    </div>
  );
};

export default Missions;
