import React from "react";
import Box1 from "../assets/img/home/the-mission/mission-1.svg";
import Box2 from "../assets/img/home/the-mission/box-2.svg";
import Discs from "../assets/img/home/the-mission/discs.svg";
import Master from "../assets/img/home/the-mission/master.svg";
import MasterMobile from "../assets/img/home/the-mission/master-mobile.svg";
import BottomBar from "../assets/img/home/the-mission/bottom-bar.svg";
import { useNavigate } from "react-router-dom";
const items = [
  {
    id: "1",
    boxImg: Box1,
    boxText: "",
  },
  {
    id: "2",
    boxImg: null,
    boxTitle: "MISSION #2",
    boxDesc: "Coming Soon",
  },
  {
    id: "3",
    boxImg: null,
    boxTitle: "MISSION #3",
    boxDesc: "Coming Soon",
  },
  {
    id: "4",
    boxImg: null,
    boxTitle: "MISSION #4",
    boxDesc: "Coming Soon",
  },
];
const TheMissionItems = () => {
  const navigate = useNavigate();
  const joinMission = (e) => {
    e.preventDefault();
    document.getElementById("mission").scrollIntoView({behavior: "smooth"})
  }

  const handleModal = (id) => {
    if(+id === 1) {
      navigate('/disc/' + id);
    }
  }
  return (
    <div className="container items-column the-mission-items-section">
      <div id="story" className="the-mission-items">
      <h1 className="header-text">
      <span className="green">How it all started </span>
          </h1>
        <p>Click on these Thrive-Master disks to stay updated with our story...</p>

        <div className="all-items-wrapper">
        <img src={Master} className="master-img" alt="Master" />
        <img src={MasterMobile} className="master-img-mobile" alt="Master" />
          {items.map((item) => (
            <div onClick={() => handleModal(item.id)} className={item.boxImg ? "box-image" : "box-image box-image-bg"}>
              <p className="bx-title">{item.boxTitle}</p>
              <p className="bx-desc">{item.boxDesc}</p>
              {item.boxImg &&
              <div className="image-disc">
                  <img src={Discs} className="discs-img" alt="box" />
                 <img src={item.boxImg} alt="box" className="box-img-inside" />
                </div>
             }
            </div>
          ))}
        </div>

        <div className="button-bar-btn">
          <div className="btn-inside-wrapper">
          <img src={BottomBar} />
          <button className="btn btn--greenish" onClick={joinMission}>
            Join Our Mission
          </button>
          </div>

        </div>
      </div>
    </div>
  );
};

export default TheMissionItems;
