import React from "react";
import Footer from '../Footer';
import BannerBelief from './BannerBelief';
import Section2WhoWeAre from './Section2WhoWeAre';
import Section3Goal from './Section3Goal';
import Section4WhatWeDo from './Section4WhatWeDo';

const OurBelief = () => {
  return (
    <div>
        <div className="gr-blue banner-wrapper">
        <BannerBelief />
        <div className="next-section center bg-navy2"></div>
      </div>

      <div className="gr-navy section2-who-we-are-wrapper">
        <Section2WhoWeAre />
        <div className="next-section center"></div>
      </div>

      <div className="gr-navy section3goal-wrapper">
        <Section3Goal />
        <div className="next-section center"></div>
      </div>

      <div className="gr-navy section4-what-we-do-wrapper">
        <Section4WhatWeDo />
        <div className="next-section center"></div>
      </div>

      <div className="fp-auto-height footer-wrapper">
        <Footer />
      </div>
    </div>
  );
};

export default OurBelief;
