import React from "react";
import Discord from "../assets/img/home/discord.svg";
import Twitter from "../assets/img/home/twitter.svg";
import Whitelist from "../assets/img/home/whitelist.svg";
import BgStars from "../assets/img/home/join/bg-stars.svg";
import Bar from "../assets/img/home/join/bar.svg";
import { Tooltip } from "@thrivecoin/ui-components";

const StartLegend = () => {
  return (
    <div className="container items-column join-section">
      <div className="join">
        <img src={BgStars} className="bg-stars-img" alt="BgStars" />
        <div className="join-content">
          <div className="join-content-description">
            <h1 className="header-text">Become a Missionary</h1>
            <p className="description">
            Follow all <span>3 steps</span> below and get thanked with <span>75 
              <Tooltip
                $minWidth="300px"
                direction="top"
                message={
                  "$THRIVE is the testnet ERC-20 token The Missionaries use to thank contributions. It will soon be redeemable for rewards. We'll share much more when we launch the first Mission."
                }
              >
                 $THRIVE
              </Tooltip>
              </span>
            </p>
          </div>
        </div>
        <div className="missionary-boxes-wrapper">
          <a className="missionary-box pulse" target="_blank" href="https://forms.gle/zwbBteXeALCGiymS6">
            <div className="missionary-box-number">
              <span>1</span>
            </div>
            <div className="missionary-box-inside">
              <h1>Get whitelisted for our first NFT Drop</h1>
              <img src={Whitelist} alt="Whitelist" className="whitelist-icon" />
            </div>
          </a>

          <a className="missionary-box missionary-box--green pulse" target="_blank"  href="https://discord.com/invite/GqZ6s6hZQT">
            <div className="missionary-box-number">
              <span>2</span>
            </div>
            <div className="missionary-box-inside">
              <h1>Join our Discord</h1>
              <img src={Discord} alt="Discord" className="discord-icon" />
            </div>
          </a>

          <a className="missionary-box missionary-box--blue pulse" target="_blank" href="https://twitter.com/missionariesnft">
            <div className="missionary-box-number">
              <span>3</span>
            </div>
            <div className="missionary-box-inside">
              <h1>Follow us on Twitter</h1>
              <img src={Twitter} alt="Twitter" className="twitter-icon" />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default StartLegend;
