import React from "react";
import BannerImg from "../assets/img/home/banner/banner-bg-tree.svg";
import CloudsImg from "../assets/img/home/banner/clouds.svg";
import CloudsTabletImg from "../assets/img/home/banner/clouds-tablet.svg";
import CloudsMobileImg from "../assets/img/home/banner/clouds-mobile.svg";
import TruckImg from "../assets/img/home/banner/truck.svg";
import TruckTabletImg from "../assets/img/home/banner/truck-tablet.svg";
import TruckMiniTabletImg from "../assets/img/home/banner/truck-mini-tablet.svg";
import TruckMobileImg from "../assets/img/home/banner/truck-mobile.svg";
import Header from "./Header";

const BannerHeader = () => {
  const watchStory = (e) => {
    e.preventDefault();
    document.getElementById("story").scrollIntoView({behavior: "smooth"})
  }

  return (
    <div className="container banner-wrap">
      <Header />
      <div className="banner">
        <div className="banner-header-image">
          <img className="bg-image" src={BannerImg} alt="Banner" />
          <img className="clouds-img" src={CloudsImg} alt="CloudsImg" />
          <img className="clouds-tablet-img" src={CloudsTabletImg} alt="CloudsImg" />
          <img className="clouds-mobile-img" src={CloudsMobileImg} alt="CloudsImg" />
          <img className="truck-img" src={TruckImg} alt="TruckImg" />
          <img className="truck-tablet-img" src={TruckTabletImg} alt="TruckImg" />
          <img className="truck-mini-tablet-img" src={TruckMiniTabletImg} alt="TruckImg" />
          <img className="truck-mobile-img" src={TruckMobileImg} alt="TruckMobileImg" />
        </div>

        <div className="banner-header-text">
          <h1 className="header-text">
         We are The Missionaries. 
         </h1>
           <p className="description">
            We're on series of missions to thank every web3 contribution in the world
           <br/>
           - with crypto and an on-chain thank-you note!
          <br/><br/>
          <span> When we thank 1 billion contributions, <br/> a Holy Swiss Vault will be opened.</span>
                   
            </p>
       

          <a onClick={watchStory} rel="noreferrer">
            <button className="btn">
            Watch the Story
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default BannerHeader;
