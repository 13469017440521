import React from "react";
import { Modal } from "@thrivecoin/ui-components";
import WheelCarousel from "./WheelCarousel";
import {Link, useLocation} from "react-router-dom";
import CHAPTER1_SLIDE1 from "../assets/img/story/chapter1_slide1.svg"
import CHAPTER1_SLIDE2 from "../assets/img/story/chapter1_slide2.svg"
import CHAPTER1_SLIDE3 from "../assets/img/story/chapter1_slide3.svg"
import CHAPTER1_SLIDE4 from "../assets/img/story/chapter1_slide4.svg"
import CHAPTER1_SLIDE5 from "../assets/img/story/chapter1_slide5.svg"
import CHAPTER1_SLIDE6 from "../assets/img/story/chapter1_slide6.svg"
import CHAPTER1_SLIDE7 from "../assets/img/story/chapter1_slide7.svg"
import CHAPTER1_END from "../assets/img/story/chapter1_end.svg"
import CHAPTER0 from "../assets/img/story/chapter0.svg"
import CHAPTER20 from "../assets/img/story/chapter20.svg"
import CHAPTER2_SLIDE1 from "../assets/img/story/chapter2_slide1.svg"
import CHAPTER2_SLIDE2 from "../assets/img/story/chapter2_slide2.svg"
import CHAPTER2_SLIDE3 from "../assets/img/story/chapter2_slide3.svg"
import CHAPTER2_SLIDE4 from "../assets/img/story/chapter2_slide4.svg"
import CHAPTER2_SLIDE5 from "../assets/img/story/chapter2_slide5.svg"
import CHAPTER2_SLIDE6 from "../assets/img/story/chapter2_slide6.svg"
import CHAPTER2_SLIDE7 from "../assets/img/story/chapter2_slide7.svg"
import CHAPTER2_SLIDE8 from "../assets/img/story/chapter2_slide8.svg"
import MISSION_1_INTRO from "../assets/img/story/mission_1_intro.svg"
import MISSION_1_1 from "../assets/img/story/mission_1_1.svg"
import MISSION_1_2 from "../assets/img/story/mission_1_2.svg"
import MISSION_1_3 from "../assets/img/story/mission_1_3.svg"
import MISSION_1_4 from "../assets/img/story/mission_1_4.svg"
import MISSION_1_5 from "../assets/img/story/mission_1_5.svg"
import MISSION_1_6 from "../assets/img/story/mission_1_6.svg"
import MISSION_1_7 from "../assets/img/story/mission_1_7.svg"
import MISSION_1_8 from "../assets/img/story/mission_1_8.svg"
import MISSION_1_9 from "../assets/img/story/mission_1_9.svg"
import MISSION_1_10 from "../assets/img/story/mission_1_10.svg"

const chapter1 = [

  {
    chapter: "chapter1",
    img: MISSION_1_INTRO,
    text: ``
  },

  {
    chapter: "chapter1",
    img: MISSION_1_1,
    text: `Hey hey hey, this is OG.
    I’m a badass, visionary giraffe.
    I’m also one of the OG Missionaries.`
  },
  {
    chapter: "chapter1",
    img: MISSION_1_2,
    text: `We believe in an animal kingdom
    where people who create value…
    are thanked for the value they create!`
  },
  {
    chapter: "chapter1",
    img: MISSION_1_3,
    text: `Bam!
    Yeah, just like that!
    Thank you thank you thank you.`
  },
  {
    chapter: "chapter1",
    img: MISSION_1_4,
    text: `See, the key to life is thank you.
    When animals are thanked, we do more worth thanking
    When animals do more worth thanking, the world is better.`
  },
  {
    chapter: "chapter1",
    img: MISSION_1_5,
    text: `So we’re going on an epic Mission
    To thank every web3 contribution
    with crypto and an on-chain thank you note.`
  },
  {
    chapter: "chapter1",
    img: MISSION_1_6,
    text: `When we thank 1 billion contributions
    A Holy Swiss Vault will be opened
    And its treasures split amongs The Missionaries.`
  },
  {
    chapter: "chapter1",
    img: MISSION_1_7,
    text: `To thank every web3 contributions
    We need a lot of Missionaries helping.
    So our first mission is simple:`
  },
  {
    chapter: "chapter1",
    img: MISSION_1_8,
    text: `We want to convert 5000 Missionaries
    Over the next 2 weeks
    Who are excited to join us.`
  },
  {
    chapter: "chapter1",
    img: MISSION_1_9,
    text: `We’ll thank our Missionaries with
    Crypto, a thank you note, and a WL list spot
    Oh, and a bunch of other prizes too!`
  },
  {
    chapter: "chapter1",
    img: MISSION_1_10,
    text: `Join me and become a missionary`
  },
]

const chapter2 = [

  {
    chapter: "chapter2",
    img: CHAPTER20,
    text: `The Missionaries \n Intro Story \n Reel 2 of 4`
  },

  {
    chapter: "chapter2",
    img: CHAPTER2_SLIDE1,
    text: `OG had a dream. 
    In that dream, he stood at the base 
    of a magical, mystical mountain
    full of fire, snow, and ice. 
    It was so big, he couldn’t see the top.
    But he understood he had to climb.`
  },
  {
    chapter: "chapter2",
    img: CHAPTER2_SLIDE2,
    text: `He started up the mountain.  
    His arms shivered from the cold. 
    His behind smoldered from the fire. 
    Still, he climbed all day and all night.
    Until finally, he reached the top. 
    There, at the top, he found...`
  },
  {chapter: "chapter2",
    img: CHAPTER2_SLIDE3,
    text: `A hot dog truck! 
    And near that that truck stood
    The Oracle. 
    (In the form of a sheep dog.) `
  },
  {
    chapter: "chapter2",
    img: CHAPTER2_SLIDE4,
    text: `
    “Why are you here?” asked the sheep dog.  
    “I... I don’t know,” OG replied. 
    “You are permitted to ask just one question.  
    OG pondered what he might ask.
    Then, in a low voice, he said: 
    “What’s the shizzle, dawg?” 
     
    `
  },
  {
    chapter: "chapter2",
    img: CHAPTER2_SLIDE5,
    text: `The sheep dog jumped on the truck 
    and raised its cane: 
    “You must go on 50 missions 
    with a growing group of friends to...” 
    Lightning struck. 
    And OG sneezed.
    `
  },
  {
    chapter: "chapter2",
    img: CHAPTER2_SLIDE6,
    text: `He rubbed his eyes. 
    Waking after a deep slumber.
    He blinked and looked around. 
    “I understand now!” he whispered.
    “What?” asked Bumble Bee, resting on his nose.`
  },
  {
    chapter: "chapter2",
    img: CHAPTER2_SLIDE7,
    text: `
    “I need to...  go on 50 missions.” 
    Bumble Bee buzzed with excitement.
    “50 missions. Why?” 
    “I don’t know,” OG said, scratching his nose.
    “But I think I need you and the rest of the Circle.” 
    `
  },
  {
    chapter: "chapter2",
    img: CHAPTER2_SLIDE8,
    text: `
    OG climbed the barren tree. 
    He looked out as far as he could see. 
    There, he took an epic selfie 
    and sent it to the Circle of Thrive. 
    It contained a simple, cryptic message: 
    “HELP!” 
    `
  },
  {
    chapter: "chapter2",
    img: ``,
    text: `To be continued...`
  },
]
const ModalStory = () => {

  const location = useLocation().pathname;

  return (
    <div className="wrapper-of-carousel">
     <WheelCarousel discTitle="The Story" discSubtitle={location === '/disc/1' ? "Deception (Disc 1/4)" : "Deception (Disc 2/4)"} chapter={location === '/disc/1' ? chapter1 : chapter2}   />
     </div>
  );
};


export default ModalStory;
