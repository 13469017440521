import React from "react";
import BannerImg from "../../assets/img/belief/leaf.svg";
import Header from "../Header";

const BannerBelief = () => {
  return (
    <div className="container banner-belief-wrap">
      <Header />
      <div className="banner-belief">
        <div className="banner-belief-header-text">
          <h1 className="header-text">
          Our belief in the power of web3. <br/>
            In Thrive we Trust.
          </h1>

          <p class="description">
          We want to build a world where all people can use their unique skills, talents, and perspectives to support their communities in thriving. 
       
       <br/><br/> To do it, we must, together, build a “Contribution Economy” where all people are equitably rewarded for every contribution they make to their communities. 
           </p>
        </div>

        <div className="banner-belief-header-image">
          <img className="leaf" src={BannerImg} alt="Banner"/>
         
        </div>
      </div>
    </div>
  );
};

export default BannerBelief;
