import { Box, IconSvg, Text,Input } from "@thrivecoin/ui-components";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as LeftArrow } from "../assets/img/leftArrow.svg";
import { ReactComponent as RightArrow } from "../assets/img/rightArrow.svg";
import { ReactComponent as ShareIcon } from "../assets/icons/share_icon.svg";
import audio from "../assets/sounds/view_master.mp3";
import Discord from "../assets/img/home/discord.svg";
import Twitter from "../assets/img/home/twitter.svg";
import Whitelist from "../assets/img/home/whitelist.svg";
import { useNavigate } from "react-router-dom";

const useAudio = (url) => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);
  const play = () => setPlaying(playing);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing]);

  useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, []);

  return [playing, toggle, play];
};

const useDelayUnmount = (isMounted, delayTime) => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    let timeoutId;
    if (isMounted && !shouldRender) {
      setShouldRender(true);
    } else if (!isMounted && shouldRender) {
      timeoutId = setTimeout(() => setShouldRender(false), delayTime);
    }
    return () => clearTimeout(timeoutId);
  }, [isMounted, delayTime, shouldRender]);
  return shouldRender;
};

const Item = ({ visible, item, initialIndex, onClick = () => {}, pointer }) => {
  const shouldRender = useDelayUnmount(visible, 500);
  if (shouldRender) {
    return (
      <AnimatedView pointer={pointer} onClick={onClick}>
        <AnimationParagraph
          className={
            !initialIndex
              ? visible
                ? "animateIn"
                : "animateOut"
              : "initialText"
          }
        >
          {item.text}
        </AnimationParagraph>
        <ImageContainer
          className={
            !initialIndex
              ? visible
                ? "animateIn"
                : "animateOut"
              : "initialImage"
          }
        >
          <AnimatedImage visible={visible} src={item.img} alt="Slide" />
        </ImageContainer>
      </AnimatedView>
    );
  }
  return <></>;
};


const ShareItem = ({ visible, initialIndex, item }) => {
  const shouldRender = useDelayUnmount(visible, 500);

  if (shouldRender) {
    return (
      <AnimatedView>
        <AnimationParagraphShares className={visible ? "animateIn1" : "animateOut1"}>
          <>
            {item.text} <br/>
            The next story reel will drop <br/>
            <TextAug>Sept 12th 2022</TextAug>
          </>
        </AnimationParagraphShares>
        <ShareContainer
          className={
            !initialIndex
              ? visible
                ? "animateIn"
                : "animateOut"
              : "initialImage"
          }
        >
          <TextInside>
            Follow along as we prepare to launch our first mission:
          </TextInside>
          <WrapperSocials>
            <a
              href="https://discord.com/invite/GqZ6s6hZQT"
              target="_blank"
              rel="noreferrer"
            >
              <button className="btn btn--secondary">
                Join us on Discord <br/>
                earn $20 THRIVE
              </button>
            </a>
            <a
              href="https://twitter.com/missionariesnft"
              target="_blank"
              rel="noreferrer"
            >
              <button className="btn btn--secondary">
                Follow us on Twitter <br/>
                Earn 20 $THRIVE
              </button>
            </a>
          </WrapperSocials>
        </ShareContainer>
      </AnimatedView>
    );
  }
  return <></>;
};

const JoinItem = ({ visible, initialIndex, item }) => {
  const shouldRender = useDelayUnmount(visible, 500);

  if (shouldRender) {
    return (
      <AnimatedView>
        <AnimationParagraphShares className={visible ? "animateIn1" : "animateOut1"}>
          <>
            {item.text} <br/>
            The next story reel will drop <br/>
            <TextAug>Sept 12th 2022</TextAug>
          </>
        </AnimationParagraphShares>
        <JoinContainer
          className={
            !initialIndex
              ? visible
                ? "animateIn"
                : "animateOut"
              : "initialImage"
          }
        >
          <BoxTextWrap>
            <TextTitle>
              In the meantime
            </TextTitle>
            <TextTitleBottom>
              Follow all 3 steps below and get thanked with 75 $THRIVE.
            </TextTitleBottom>
          </BoxTextWrap>
          <WrapperOfBoxes>
            <div className="missionary-boxes-wrapper missionary-boxes-wrapper--wheel">
              <a
                className="missionary-box pulse wheel-card"
                target="_blank"
                rel="noreferrer"
                href="https://forms.gle/zwbBteXeALCGiymS6"
              >
                <div className="missionary-box-number">
                  <span>1</span>
                </div>
                <div className="missionary-box-inside">
                  <h1>Get whitelisted for our first NFT Drop</h1>
                  <img src={Whitelist} alt="Whitelist" className="whitelist-icon" />
                </div>
              </a>

              <a
                className="missionary-box missionary-box--green pulse wheel-card"
                target="_blank"
                rel="noreferrer"
                href="https://discord.com/invite/GqZ6s6hZQT"
              >
                <div className="missionary-box-number">
                  <span>2</span>
                </div>
                <div className="missionary-box-inside">
                  <h1>Join our Discord</h1>
                  <img src={Discord} alt="Discord" className="discord-icon" />
                </div>
              </a>

              <a
                className="missionary-box missionary-box--blue pulse wheel-card"
                target="_blank"
                rel="noreferrer"
                href="https://twitter.com/missionariesnft"
              >
                <div className="missionary-box-number">
                  <span>3</span>
                </div>
                <div className="missionary-box-inside">
                  <h1>Follow us on Twitter</h1>
                  <img src={Twitter} alt="Twitter" className="twitter-icon" />
                </div>
              </a>
            </div>
          </WrapperOfBoxes>
        </JoinContainer>
      </AnimatedView>
    );
  }
  return <></>;
};

const WheelCarousel = ({ chapter, discTitle, discSubtitle, hideModal }) => {
  const [initialIndex, setInitialIndex] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [circleClass, setCircleClass] = useState("");
  const shouldNavigateRender = useDelayUnmount(!circleClass, 300);
  const [play, toggle] = useAudio(audio);
  const isMobile = window.innerWidth  < 990
  const navigate = useNavigate();
  const goBack = () => {
    navigate('/');
  }
  const onNext = () => {
    setCircleClass("animateLeft");
    if (initialIndex) {
      setInitialIndex(false);
    }
    if (currentIndex === chapter.length - 1) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(currentIndex + 1);
    }
    !isMobile && toggle();
  };

  const onBack = () => {
    setCircleClass("animateRight");
    if (initialIndex) {
      setInitialIndex(false);
    }
    if (currentIndex === 0) {
      setCurrentIndex(chapter.length - 1);
    } else {
      setCurrentIndex(currentIndex - 1);
    }
    !isMobile && toggle();
  };


  
let touchstartX = 0
let touchendX = 0
    
function checkDirection() {
  if (touchendX > touchstartX){
    setCircleClass("animateRight");
    if (initialIndex) {
      setInitialIndex(false);
    }
    if (currentIndex === 0) {
      setCurrentIndex(chapter.length - 1);
    } else {
      setCurrentIndex(currentIndex - 1);
    }
    toggle();
  }
  if (touchendX < touchstartX) {
    setCircleClass("animateLeft");
    if (initialIndex) {
      setInitialIndex(false);
    }
    if (currentIndex === chapter.length - 1) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(currentIndex + 1);
    }
    toggle();

  }
}

document.addEventListener('touchstart', e => {
  touchstartX = e.changedTouches[0].screenX
})

document.addEventListener('touchend', e => {
  touchendX = e.changedTouches[0].screenX
  checkDirection()
})
  return (
    <Wrapper
      relative
      height="100vh"
      flex
      direction="column"
      alignItems="center"
    >
      <BigCircle
        className={circleClass}
        onAnimationEnd={() => setCircleClass("")}
      >
        <Box relative width="100%" height="100%">
          <InnerCirlce>
            <StyledBox  bgColor={chapter[0].chapter === 'chapter2' && "#26385C"}/>
          </InnerCirlce>
          <InnerCirlce>
            <StyledBox  bgColor={chapter[0].chapter === 'chapter2' && "#26385C"}/>
          </InnerCirlce>
          <InnerCirlce>
            <StyledBox bgColor={chapter[0].chapter === 'chapter2' && "#26385C"}/>
          </InnerCirlce>
          <InnerCirlce>
            <StyledBox bgColor={chapter[0].chapter === 'chapter2' && "#26385C"}/>
          </InnerCirlce>
          <InnerCirlce>
            <StyledBox bgColor={chapter[0].chapter === 'chapter2' && "#26385C"}/>
          </InnerCirlce>

          {/* {chapter.map((item, index) => {

            if (item.chapter === 'chapter1' && index === 9) {
              return (
                <ShareItem
                  visible={index === currentIndex}
                  index={index}
                  initialIndex={initialIndex}
                  item={item}
                />
              );
            }


            if (item.chapter === 'chapter2' && index === 9) {
              return (
                <JoinItem
                  visible={index === currentIndex}
                  index={index}
                  initialIndex={initialIndex}
                  item={item}
                />
              );
            }
          })} */}

        {chapter[0].chapter === "chapter1" && (
          <Item
            pointer
            item={chapter[10]}
            visible={10 === currentIndex}
            index={10}
            initialIndex={initialIndex}
            onClick={() => window.open('https://app.thrivecoin.com/missions/1', '_blank').focus()}
          />
        )}

        {chapter.map((item, index) => {

            if (item.chapter === 'chapter1' && index !== 10) {

              return (<Item item={item} visible={index === currentIndex} index={index} initialIndex={initialIndex} />)
            }
            if (item.chapter === 'chapter2' && index !== 9) {

              return (<Item item={item} visible={index === currentIndex} index={index} initialIndex={initialIndex} />)
            }
          })}

          {shouldNavigateRender && (
            <Navigate visible={!circleClass} flex >
              <LeftArrow onClick={onBack} />
              <RightArrow onClick={onNext} />
            </Navigate>
          )}
        </Box>
      </BigCircle>
      {/* <Copyright>© 2022 ThriveCoin. All rights reserved.</Copyright> */}
      <DiskName color="#fff" fontFamily="Outfit" size="22px">
        <span>{discTitle}</span>
        {<br />}
        {discSubtitle}
      </DiskName>
      <ModalButtons>
        <RoundedButton onClick={goBack}>
          <IconSvg icon="x" width="26px" color="#fff" />
        </RoundedButton>
        {/*<RoundedButton>
          <ShareIcon />
        </RoundedButton>*/}
      </ModalButtons>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  overflow: hidden;
  
  .missionary-boxes-wrapper {
    flex-wrap: nowrap;
    @media (max-width: 960px){
    flex-wrap: nowrap;
    padding: 24px;
    gap: 86.15px;
      
 }

.missionary-box::before {
  @media (max-width: 960px){
  top: 50%;
  right: -36px;
  left:unset;
  bottom: unset;
  transform: translateY(-50%) rotate(180deg);
  }
 }
}
`;

const AnimatedView = styled.div`
  position: absolute;
  // width: 600px;
  left: 50%;
  bottom: 100px;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;

  ${({pointer}) => !!pointer && `cursor: pointer;`}

  @media  (max-width: 1512px) and (max-height: 960px) {
    bottom: 60px;

  }
  @media (max-width: 768px){
    bottom: 154px;
  }
 
`;

const ImageContainer = styled.div`
  opacity: 0;

  &.animateIn {
    animation: animationIn 1s forwards;
    animation-delay: 1.5s;
  }
  &.animateOut {
    animation: animationOut 0.5s forwards;
  }

  &.initialImage {
    animation: animationIn 1s forwards;
  }

  @keyframes animationIn {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0.2;
    }
    40% {
      opacity: 0.4;
    }
    60% {
      opacity: 0.6;
    }
    80% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes animationOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
`;

const AnimatedImage = styled.img`
  width: 600px;
  height: 400px;
  object-fit: cover;
  border-radius: 60px;
  @media (max-width: 1512px) and (max-height: 960px) {
    width: 500px;
    height: 300px;
  }
  @media (max-width: 768px){
    width: 458.07px;
    height: 299.53px;
  }
  @media (max-width: 450px){
    width: 354.52px;
    height: 230.51px;
    border-radius: 20.481px;
  }
`;

const BigCircle = styled.div`
  position: absolute;
  background-color: #0b1428;
  width: calc(100vw + 1200px);
  height: calc(100vw + 1200px);
  top: calc(100vh - calc(100vw + 1243px));
  border-radius: 50%;
  transform: rotate(0);

  @media (max-width: 1199.98px){
    height: calc(100vw + 1222px);
  }
  @media (max-width: 768px){
    height: calc(100vw + 1090px);
  }

  @media (max-width: 450px){
    top: calc(100vh - calc(100vw + 1180px));
  }

 
  &.animateRight {
    animation: animateLeftToRight 1s forwards;
    animation-delay: 0.5s;
  }
  &.animateLeft {
    animation: animateRightToLeft 1s forwards;
    animation-delay: 0.5s;
  }

  @keyframes animateLeftToRight {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(-45deg);
    }
  }

  @keyframes animateRightToLeft {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(45deg);
    }
  }
`;

const InnerCirlce = styled.div`
  position: absolute;
  width: calc(100vw + 1200px);
  height: calc(100vw + 1200px);
  border-radius: 50%;
  @media  (max-width: 1512px) and (max-height: 960px) {
    height: calc(100vw + 1240px);
  }
  @media (max-width: 1199.98px){
    height: calc(100vw + 1024px);
  }
  @media (max-width: 768px){
    height: calc(100vw + 925px);
  }
 

  &:nth-child(1) {
    transform: rotate(90deg);
  }
  &:nth-child(2) {
    transform: rotate(51deg);
  }
  &:nth-child(3) {
    transform: rotate(0deg);
  }
  &:nth-child(4) {
    transform: rotate(-51deg);
  }
  &:nth-child(5) {
    transform: rotate(-90deg);
  }
`;

const StyledBox = styled.div`
  position: absolute;
  width: 600px;
  height: 400px;
  border-radius: 60px;
  left: 50%;
  bottom: 100px;
  transform: translate(-50%, 0);
  background-color: ${({ bgColor }) =>
  bgColor ? bgColor : "#1b263f"};
  @media  (max-width: 1512px) and (max-height: 960px) {
    width: 500px;
    height: 300px;

  }
  @media (max-width: 1199.98px){
    bottom: -100px;
  }

  @media (max-width: 768px){
    width: 458.07px;
    height: 299.53px;
    bottom: -10px;
  }

  @media (max-width: 450px){
    width: 351.52px;
    height: 230.51px;
    border-radius: 20.481px;
  }
`;

const Navigate = styled(Box)`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 278px;
  animation: ${({ visible }) =>
      !!visible ? "animateIn 1.5s" : "animateOut .5s"}
    forwards;
  animation-delay: ${({ visible }) => (!!visible ? "3s" : "0")};
  gap: 700px;

  @media  (max-width: 1512px) and (max-height: 960px) {
    bottom: 185px;
  }
  @media (max-width: 768px){
    gap: 500px;
  }
  @media(max-width: 450px) {
    gap: 66px;
    bottom: 70px;

    svg {
      width: 40px;
      height: 40px;
    }
}
  & svg {
    cursor: pointer;
    color: #3B5277;
  }

  & svg:hover {
    transform: scale(1);
    animation: bounce 1.3s ease-in-out infinite, colorChange .6s;
    color: white;
  }

  @keyframes bounce {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.3);
    }
  }

  @keyframes colorChange {
    0% {
      color: #3B5277;
    }
    100% {
      color: #fff;
    }
  }

  @keyframes animateIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
  @keyframes animateOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
`;

const DiskName = styled(Text)`
  position: absolute;
  left: 32px;
  bottom: 32px;
  font-size: 20px;
  font-family: Outfit;
  @media  (max-width: 1512px) and (max-height: 960px) {
    font-size: 18px;
    line-height: 23px;
  }
  @media(max-width: 768px) {
    text-align: center;
    font-size: 18px;
    line-height: 23px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 41px;
  }

  @media(max-width: 450px) {
    text-align: center;
    font-size: 18px;
    line-height: 23px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 23.17px;
  }

  & span {
    font-size: 24px;
    font-weight: 600;
    
    @media(max-width: 768px) {
      text-align: center;
      font-size: 18px;
      line-height: 23px;
    }
  }
`;

const AnimationParagraph = styled.div`
  font-family: Outfit;
  font-size: 24px;
  line-height: 43px;
  font-weight: 600;
  color: #f5f5f5;
  text-align: center;
  white-space: pre-line;
  margin-bottom: 80px;
  opacity: 0;
  transform: scale(0);

  @media(max-height: 982px) {
    font-size: 22px;
    line-height: 32px;
    // margin-bottom: 24px;
  }
  @media  (max-width: 1512px) and (max-height: 960px) {
    font-size: 18px;
    line-height: 23px;
    // margin-bottom: 24px;
  }

  @media (max-width: 1199.98px){
    font-size: 22px;
    line-height: 32px;
  }

  @media (max-width: 450px){
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    margin-bottom: 55px;
  }
  &.animateIn {
    animation: animationTextIn 1s both;
    animation-delay: 1.5s;
  }
  &.animateOut {
    animation: animationTextOut 0.5s forwards;
  }

  &.initialText {

    animation: animationTextIn 1s both;
  }

  @keyframes animationTextIn {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  @keyframes animationTextOut {
    0% {
      opacity: 1;
      transform: scale(1);
    }

    100% {
      opacity: 0;
      transform: scale(0);
    }
  }
`;

const AnimationParagraphShares = styled.div`
  font-family: Outfit;
  font-size: 24px;
  line-height: 43px;
  font-weight: 600;
  color: #f5f5f5;
  text-align: center;
  white-space: pre-line;
  margin-bottom: 40px;
  opacity: 0;
  transform: scale(0);

  &.animateIn1 {
    animation: animationTextIn 1s both;
    animation-delay: 1.5s;
  }
  &.animateOut1 {
    animation: animationTextOut 0.5s forwards;
  }

  &.initialText1 {
    animation: animationTextIn 1s both;
  }

  @keyframes animationTextIn1 {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  @keyframes animationTextOut1 {
    0% {
      opacity: 1;
      transform: scale(1);
    }

    100% {
      opacity: 0;
      transform: scale(0);
    }
  }
`;
const ModalButtons = styled.div`
  position: absolute;
  top: 155px;
  right: 36px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 1199.98px){
    top: 154.32px;
    right: 31px;
  }
  @media (max-width: 450px){
    top: 18px;
    right: 18px;
  }
`;

const RoundedButton = styled.div`
  width: 42px;
  height: 42px;
  border-radius: 50px;
  background-color: #374668;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
 

`;

const ShareContainer = styled.div`
  opacity: 0;
  background: #81c394;
  width: 600px;
  height: 400px;
  border-radius: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  @media  (max-width: 1512px) and (max-height: 960px) {
    width: 500px;
    height: 300px;
  }
  @media (max-width: 1199.98px){
    bottom: -100px;
  }

  @media (max-width: 768px){
    width: 458.07px;
    height: 299.53px;
    bottom: -10px;
  }

  @media (max-width: 450px){
    width: 351.52px;
    height: 230.51px;
    border-radius: 20.481px;
  }

  &.animateIn {
    animation: animationIn 1s forwards;
    animation-delay: 1.5s;
  }
  &.animateOut {
    animation: animationOut 0.5s forwards;
  }

  &.initialImage {
    animation: animationIn 1s forwards;
  }

  @keyframes animationIn {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0.2;
    }
    40% {
      opacity: 0.4;
    }
    60% {
      opacity: 0.6;
    }
    80% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes animationOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
`;

const JoinContainer = styled.div`
  opacity: 0;
  background: #1C2942;
  width: 600px;
  height: 400px;
  border-radius: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  @media  (max-width: 1512px) and (max-height: 960px) {
    width: 500px;
    height: 300px;
  }
  @media (max-width: 1199.98px){
    bottom: -100px;
  }

  @media (max-width: 768px){
    width: 458.07px;
    height: 299.53px;
    bottom: -10px;
  }

  @media (max-width: 450px){
    width: 351.52px;
    height: 230.51px;
    border-radius: 20.481px;
  }

  &.animateIn {
    animation: animationIn 1s forwards;
    animation-delay: 1.5s;
  }
  &.animateOut {
    animation: animationOut 0.5s forwards;
  }

  &.initialImage {
    animation: animationIn 1s forwards;
  }

  @keyframes animationIn {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0.2;
    }
    40% {
      opacity: 0.4;
    }
    60% {
      opacity: 0.6;
    }
    80% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes animationOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
`;
const TextInside = styled.span`
  font-family: "Outfit";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  text-align: center;
  color: white;
`;
const AnimatedViewShare = styled.div`
  position: absolute;
  // width: 600px;
  left: 50%;
  bottom: 100px;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
`;

const TextAug = styled.span`
  font-family: "Outfit";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 20px;
  color: #e2857e;
  text-align: center;
  padding: 0 44px;
  min-width: 460px;
  transform: scale(0.9);
`;

const JoinContainerInside = styled.div`
  padding: 0 44px;
  height: 378.59px;
  width: 600px;
  margin-bottom: -20px;
  transform: scale(0.9);
`;

const JoinContainerInsideTop = styled.div`
  background: #1C2942;
  border-radius: 21px;
  height: 260px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;


const WrapperSocials = styled.div`
  display: flex;
  gap: 27.55px;

  .btn {
    width: 216px;
    height: 80px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: 575.98px){
    gap: 8px;
    .btn {
      width: unset;
    }
  }
`

const TextTitle = styled.div`
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  text-align: center;
  color: #8DCF9F;
  margin-bottom: 8px;
`

const TextTitleBottom = styled.div`
font-family: 'Outfit';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 23px;
text-align: center;
color: #FFFFFF;
`

const WrapperOfBoxes = styled.div`
  display: flex;
  transform: scale(0.4);
  margin-bottom: -90px;
  &>div {
    margin-top: 0;
  }
`

const BoxTextWrap = styled.div`
margin-bottom: -35px;
@media (max-width: 450px){
  margin-bottom: -55px;
}
`

const Copyright = styled.span`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 34px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #586F94;
  position: absolute;
  bottom: 73.25px;
  @media  (max-width: 1512px) and (max-height: 960px) {
    bottom: 60px;
  }

  @media (max-width:768px){
   bottom: 200px;
   font-weight: 400;
   font-size: 12px;
   line-height: 18px;
  }
  @media (max-width: 450px){
    bottom: 113.67px;
  }
`
export default WheelCarousel;
