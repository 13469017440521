import React from "react";
import BarTop from "../assets/img/home/the-final-destination/top-bar.svg";
import BarTopTablet from "../assets/img/home/the-final-destination/top-bar-tablet.svg";
import Safe from "../assets/img/home/the-final-destination/safe.svg";
import SafeTablet from "../assets/img/home/the-final-destination/safe-tablet.svg";
import Bee from "../assets/img/home/the-story/bee.svg";

const TheFinalDestination = () => {
  return (
    <div className="container items-column the-final-destination-section">
      <div className="the-final-destination">
  
        <div className="bar-wrapper-image">
          <img src={BarTop} className="top-bar-img" />
          <img src={BarTopTablet} className="top-bar-img-tablet" />
        </div>

        <div className="the-final-destination-image">
        <img src={Bee} alt="bee"  className="bee-image-mobile"/>
          <img src={Safe} className="safe-img" />
          <img src={SafeTablet} className="safe-img-tablet" />
        </div>

        <div className="the-final-destination-text">
          <h1 className="header-text">
            <span className="white">And about that Holy Swiss Vault. </span>
          </h1>

          <p className="description">
            The Holy Swiss vault contains growing riches earned from the
            contributions we make to Missions. It will be unlocked when we thank
            1 billion contributions.
            <br /> <br />
            When the Holy Swiss Vault is unlocked, 100% of the riches will be
            dispersed to contributors to our Missions - proportionate to the
            contributions they've made.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TheFinalDestination;
