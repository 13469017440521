import { useEffect } from "react";
import ReactGA from "react-ga4";

const useTrackExternalLink = () => {
  useEffect(() => {
    document.addEventListener("click", track);
    return () => {
      document.removeEventListener("click", track);
    };
  }, []);
};

const track = (event) => {
  var link = event.target;
  const location = window.location;
  while (link && (typeof link.tagName == "undefined" || link.tagName.toLowerCase() !== "a" || !link.href)) {
    link = link.parentNode;
  }

  if (link && link.href && link.host && link.host !== location.host) {
    ReactGA.event({
      category: "Outbound Link",
      action: "click",
      label: link.href,
    });
  }

  // Allow event to be sent before the page is unloaded
  if (!link.target || link.target.match(/^_(self|parent|top)$/i)) {
    setTimeout(function () {
      location.href = link.href;
    }, 150);
    event.preventDefault();
  }
};

export default useTrackExternalLink;
