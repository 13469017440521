import React, {useState} from "react";
import Logo from "../assets/img/logo.svg";
import {Link, useLocation} from "react-router-dom";
import {THRIVECOIN_APP} from "../utils/Links";

const Header = ({classHeader, logoSrc}) => {
  const [isActive, setActive] = useState(false);
  const logo = logoSrc ? logoSrc : Logo;

  const toggleClass = () => {
    setActive(!isActive);
    document.querySelector('html').classList.toggle('disable-scroll');
  };

  const location = useLocation().pathname;

  return (
    <header className={`header ${classHeader}`}>
      <div className="header-logo">
        <Link to="/">
          <img alt="logo" src={logo}></img>
        </Link>
      </div>

      <nav className={isActive ? "nav-menu active" : " nav-menu"} onClick={toggleClass}>
        {isActive && (
          <a href="https://discord.com/invite/GqZ6s6hZQT" target="_blank" rel="noreferrer">
            <button className="btn btn--secondary">
              Join us on Discord
            </button>
          </a>
        )}
        <ul>
          {/*<li className={location === '/' && "active"}>
            <Link to="/">Intro</Link>
          </li>*/}
          {/*<li className={location === '/ourbelief' && "active"}>
            <Link to="/ourbelief" className='disabled'>Manifesto</Link>
          </li>
          <li className={location === '/howitworks' && "active"}>
            <Link to="/howitworks" className='disabled'>How it works</Link>
          </li>

          <li className={location === '/missions' && "active"} >
            <Link to="/missions" className='disabled'>Missions</Link>
          </li>*/}
        </ul>
      </nav>

      <div className="header-buttons">
        <a href="https://discord.com/invite/GqZ6s6hZQT" target="_blank" rel="noreferrer">
          <button className="btn btn--secondary">
            Join us on Discord
          </button>
        </a>

        <button className={isActive ? "main-menu-indicator active" : " main-menu-indicator"} onClick={toggleClass}>
          <span className="line"></span>
        </button>
      </div>

    </header>
  );
};

export default Header;
