import React from "react";
import Globe from "../../assets/img/belief/globe.svg";

const Section4WhatWeDo = () => {
  return (
    <div className="container items-column section4-what-we-do-section">
      <div className="main-title items-center">
        <h6 className="mini-title primary">WHAT WE DO</h6>
      </div>

      <div className="section4-what-we-do">
        <div className="section4-what-we-do-content">
          <div className="section4-what-we-do-content-description mtn-60 l-height">
            <h1 className="header-text">
            ThriveCoin; The token to be used as the currency between our communities and DAOs


            </h1>
            <p className="description">
            Small introduction text about thrivecoin and that all rewards will be in $Thrive.
<br/><br/>
Who said crypto doesn’t grow on trees?
            </p>

            <a href="#" rel="noreferrer">
              <button className="btn btn--outline btn--outline--white">
              Connect Wallet
              </button>
            </a>
          </div>
        </div>

        <div className="section4-what-we-do-image">
          <img className="globe-image" src={Globe} alt="Globe Image" />
        </div>
      </div>
    </div>
  );
};

export default Section4WhatWeDo;
